<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container">
              <overview-income-expenses-chart
                :dataObject="dataArray"
                :label="'Value'"
                :startYear="2011"
                :endYear="2020"
                :birthYear="1980"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OverviewIncomeExpensesChart from '@/components/d3charts/cashbook/OverviewIncomeExpensesChart'

export default {
  components: {
    OverviewIncomeExpensesChart
  },
  data () {
    return {
      dataArray: [
        { amount: 1000, year: 2011 },
        { amount: -200, year: 2012 },
        { amount: 100, year: 2013 },
        { amount: 200, year: 2014 },
        { amount: -300, year: 2015 },
        { amount: 100, year: 2016 },
        { amount: -200, year: 2017 },
        { amount: 400, year: 2018 },
        { amount: -200, year: 2019 },
        { amount: 200, year: 2020 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    padding-top: 50px;
  }
</style>
