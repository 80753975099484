<template>
  <div class="chart-wrapper">
    <svg id="barChartRent"
         :width="width"
         :height="height">
      <g :transform="`translate(${margin.left}, ${margin.top})`" class="chart">
        <rect v-for="(d,i) in bars"
              :key="i"
              :x="xScale(d.year)"
              :y="yScale(d.value)"
              :width="xScale.bandwidth()"
              :height="chartHeight - yScale(d.value)"
              :data-year="d.year"
              :fill="barColor"
              :opacity="calcOpacity(d, i)"
              class="bar">
        </rect>
        <div v-if="debug">
        <text
              v-for="(d,i) in bars"
              :key="`text-${i}`"
              :x="xScale(d.year)"
              :y="yScale(d.value) - 8"
              :fill="darkColor">
          {{ d.value }}
        </text>
        </div>
        <rect v-for="(d,i) in bars"
              :key="`hiddenrect-${i}`"
              :x="xScale(d.year)"
              :y="0"
              :width="xScale.bandwidth()"
              :height="chartHeight + hoverrectMehrYBottom"
              :data-year="d.year"
              class="hiddenrect"
              @mouseover="rectmouseover(d, i)"
              @mouseout="rectmouseout(d, i)">
        </rect>
        <rect :x="0"
              :y="-hoverrectMehrYTop"
              :width="xScale.bandwidth()"
              :height="chartHeight + hoverrectMehrYTop + hoverrectMehrYBottom"
              fill="black"
              opacity="0.35"
              class="hoverrect">
        </rect>
      </g>
      <line :x1="0"
            :x2="width"
            :y1="margin.top + chartHeight + hoverrectMehrYBottom"
            :y2="margin.top + chartHeight + hoverrectMehrYBottom"
            :stroke="divisionLineColor"
            stroke-width="0.5">
      </line>
      <g :transform="`translate(${sliderMarginLeft}, ${margin.top + chartHeight + hoverrectMehrYBottom + 35})`"
        class="slider-wrapper">
        <slider v-if="sliderWidth && staticDomain.length && domain.length"
          :sliderWidth="sliderWidth"
          :staticDomain="staticDomain"
          :initialDomain="domain"
          :maximumSliderRange="maximumSliderRange"
          @dragged="onSliderDrag"
        />
      </g>
      <Tooltip v-if="width > 0"
               :obj="tooltipObj"
               :svgWidth="width"
               :svgHeight="height"
               :handleOverflowX="true"
               :handleOverflowY="false">
      </Tooltip>
    </svg>
  </div>
</template>

<script>
import Slider from '../d3-assets/Slider'
import Tooltip from '../d3-assets/Tooltip'
import patternify from '../d3-assets/patternify'

export default {
  name: 'OverviewIncomeExpensesChart',
  props: [
    'label',
    'dataObject',
    'startYear',
    'endYear',
    'birthYear'
  ],
  methods: {
    thousandsFormat (value) {
      const locale = this.$d3.formatLocale({
        decimal: ',',
        thousands: ' ',
        grouping: [3]
      })
      return locale.format(',')(value)
    },
    rectmouseover (d, i) {
      const html = `<div class="toolTip">
                    <div class="mt-2 year">
                      <strong><span>${d.year}</span></strong> ( age of <strong><span>${d.year - this.birthYear}</span></strong> )
                    </div>
                    <div class="d-flex mt-4">
                      <div class="mr-3">
                        <span class="income-span"></span><span class="ml-2 label-span">${this.label}</span>
                      </div>
                      <div class="ml-4">
                        <strong><span id="amount-span">${this.thousandsFormat(d.value)}</span> SEK</strong>
                      </div>
                    </div>
                  </div>`

      let x = this.xScale(d.year) + this.xScale.bandwidth() / 2 + this.margin.left
      const y = this.margin.top * 1.5

      if (x + 340 > this.width) {
        x -= 340
      }

      this.tooltipObj = {
        x: x,
        y: y,
        html: html,
        visible: true
      }

      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.year}`)

      // make circle bigger and filled white
      const xTickCircle = xTick.select('circle')
      xTickCircle.attr('fill', '#fff')
        .attr('stroke', '#1971ff')
        .attr('r', 5)

      // display hover rect
      const hoverrect = this.$d3.select('.hoverrect')
      hoverrect.attr('x', this.xScale(d.year))
        .style('display', 'block')

      // make texts a little bit bigger
      xTick.selectAll('text').attr('fill', '#fff').attr('font-weight', 600)
    },
    rectmouseout (d, i) {
      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.year}`)

      // make circle smaller
      const xTickCircle = xTick.select('circle')
      xTickCircle.attr('fill', '#1971ff')
        .attr('stroke', '#fff')
        .attr('r', 4)

      // hide hover rect
      const hoverrect = this.$d3.select('.hoverrect')
      hoverrect.style('display', 'none')

      // make texts smaller
      xTick.selectAll('text').attr('fill', this.darkColor).attr('font-weight', 400)
      this.tooltipObj.visible = false
    },
    calcOpacity (d, i) {
      const length = this.domain[this.domain.length - 1] - this.domain[0]
      const l = Math.round(length * this.capacityPercentage)
      const h = length - l

      const sc = this.$d3.scaleLinear().clamp(true)

      if (i <= l) {
        sc.domain([0, l]).range([0.25, 1])
        return sc(i)
      }
      if (i >= h) {
        sc.domain([h, this.domain.length - 1]).range([1, 0.25])
        return sc(i)
      }
      return 1
    },
    drawAxis () {
      const tickSize = this.chartHeight
      const xAxis = this.$d3.axisBottom(this.xScale).tickSize(-(tickSize))
      const yAxis = this.$d3.axisLeft(this.yScale).ticks(5).tickFormat(d => this.thousandsFormat(d) + ' Kr')

      // append xAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'xAxis'
      })
        .attr('class', 'xAxis axis')
        .attr('transform', `translate(${0}, ${this.chartHeight})`)
        .call(xAxis)

      // append yAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'yAxis'
      })
        .attr('class', 'yAxis axis')
        .call(yAxis)
    },
    adjustAxis () {
      const self = this
      const startEndTickSize = -(this.chartHeight + this.mehrHeight)

      const ticks = this.chart.select('g.xAxis')
        .selectAll('.tick')
        .attr('data-age', (d, i) => {
          const year = this.xScale.domain()[0] + i
          return year - this.birthYear
        })
        .attr('id', (d, i) => {
          const year = this.xScale.domain()[0] + i
          return `xTick-${year}`
        })
        .attr('opacity', (d, i) => {
          return this.calcOpacity(d, i)
        })

      ticks.select('line')
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', '3 1.5')

      // reposition texts within the axis
      ticks.select('text')
        .attr('class', 'tick-year')
        .attr('dy', '4em')
        .attr('fill', this.darkColor)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: ticks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('fill', this.circleColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)

      // append ages
      patternify({
        container: ticks,
        tag: 'text',
        selector: 'secondaryAxisText'
      })
        .attr('class', 'secondaryAxisText')
        .attr('dy', '2.5em')
        .attr('font-family', 'Roboto')
        .text(function (d, i) {
          const tick = self.$d3.select(this.parentElement)
          return tick.attr('data-age')
        })

      // adjust xAxis
      const yTicks = this.chart.select('g.yAxis')
        .selectAll('.tick')

      // reposition lines
      yTicks.select('line')
        .attr('x1', (d, i) => {
          if (i) {
            return -7
          }
          return 0
        })
        .attr('x2', (d, i) => {
          if (i) {
            return 4
          }
          return 0
        })
        .attr('stroke-width', 2)

      yTicks.select('text')
        .attr('x', -24)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: yTicks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('cx', -10)
        .attr('fill', '#6D7290')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)

      if (this.startYear >= this.domain[0]) {
        this.chart.selectAll('circle.startYearCircle')
          .remove()
        // start year tick selection
        const startYearTick = this.chart.select(`#xTick-${this.startYear}`)
        startYearTick
          .select('line')
          .attr('y2', startEndTickSize)
          .attr('stroke', '#fff')
          .style('opacity', 1)

        // append circle for the startYear tick
        patternify({
          container: startYearTick,
          tag: 'circle',
          selector: 'startYearCircle'
        })
          .attr('r', this.axisCircleSize)
          .attr('fill', this.circleColor)
          .attr('stroke', '#fff')
          .attr('stroke-width', 1.5)
          .attr('transform', `translate(0, ${startEndTickSize})`)

        // start year text group
        const startYearGroup = patternify({
          container: this.chart,
          tag: 'g',
          selector: 'startYear'
        })
          .attr('transform', `translate(${this.xScale(this.startYear) + this.xScale.bandwidth() / 2 + 10}, ${this.mehrHeight - this.margin.top - 15})`)

        patternify({
          container: startYearGroup,
          tag: 'text',
          selector: 'startYearTxt'
        })
          .text('Start Year')
          .attr('fill', this.darkColor)
          .attr('font-size', '0.8rem')
          .attr('font-family', 'Roboto')

        patternify({
          container: startYearGroup,
          tag: 'text',
          selector: 'startYearY'
        })
          .text(this.startYear)
          .attr('fill', '#fff')
          .attr('font-size', '13px')
          .attr('dy', 16)
          .attr('font-family', 'Roboto')
      } else {
        this.chart.selectAll('g.startYear').remove()
      }

      if (this.endYear <= this.domain[this.domain.length - 1]) {
        this.chart.selectAll('circle.endYearCircle')
          .remove()
        // end year tick selection
        const endYeartTick = this.chart.select(`#xTick-${this.endYear}`)
        endYeartTick
          .select('line')
          .attr('y2', startEndTickSize)
          .attr('stroke', '#fff')
          .attr('opacity', 0.7)

        // add circle to the end year tick
        patternify({
          container: endYeartTick,
          tag: 'circle',
          selector: 'endYearCircle'
        })
          .attr('r', this.axisCircleSize)
          .attr('fill', '#1971ff')
          .attr('stroke', '#fff')
          .attr('stroke-width', 1.5)
          .attr('transform', `translate(0, ${startEndTickSize})`)

        let translateX = this.xScale(this.endYear) + this.xScale.bandwidth() / 2 + 10
        const translateY = this.mehrHeight - this.margin.top - 15

        if (this.endYear >= this.domain[this.domain.length - 2]) {
          translateX -= 70
        }

        // end year text group
        const endYearGroup = patternify({
          container: this.chart,
          tag: 'g',
          selector: 'endYear'
        })
          .attr('transform', `translate(${translateX}, ${translateY})`)

        patternify({
          container: endYearGroup,
          tag: 'text',
          selector: 'endYearTxt'
        })
          .text('End Year')
          .attr('fill', this.darkColor)
          .attr('font-size', '0.8rem')
          .attr('font-family', 'Roboto')

        patternify({
          container: endYearGroup,
          tag: 'text',
          selector: 'endYearY'
        })
          .text(this.endYear)
          .attr('fill', '#fff')
          .attr('font-size', '13px')
          .attr('dy', 16)
          .attr('font-family', 'Roboto')
      } else {
        this.chart.selectAll('g.endYear').remove()
      }
    },
    setWidth () {
      this.width = this.$el.offsetWidth
    },
    setDomain () {
      const min = this.$d3.min(this.computedData, d => d.year)
      const max = this.$d3.max(this.computedData, d => d.year)
      this.staticDomain = this.$d3.range(min, max + 1)

      if (!this.domain.length) {
        this.domain = this.$d3.range(min, min + this.maximumSliderRange + 1)
      }
    },
    onResize () {
      this.setWidth()
      this.drawAxis()
      this.adjustAxis()
    },
    onSliderDrag (domain) {
      this.domain = domain
      this.drawAxis()
      this.adjustAxis()
    }
  },
  watch: {
    computedData () {
      this.setDomain()
      this.drawAxis()
      this.adjustAxis()
    }
  },
  mounted () {
    this.chart = this.$d3.select('#barChartRent g')
    this.setDomain()
    this.onResize()
    this.$d3.select(window).on('resize', this.onResize)
  },
  components: {
    Tooltip,
    Slider
  },
  data () {
    return {
      width: 0,
      height: 550,
      sliderHeight: 180,
      hoverrectMehrYTop: 25,
      hoverrectMehrYBottom: 60,
      mehrHeight: 60,
      axisCircleSize: 4,
      divisionLineColor: '#404A65',
      barColor: '#FEC600',
      circleColor: '#0065FF',
      darkColor: '#A5ADBA',
      sliderHandlerRadius: 12,
      domain: [],
      staticDomain: [],
      capacityPercentage: 0.2,
      tooltipObj: {
        visible: false
      },
      margin: {
        top: 100,
        right: 15,
        bottom: 15,
        left: 110
      },
      chart: '',
      debug: false
    }
  },
  computed: {
    computedData () {
      // A little bit padding around years
      // You may need to use props to pass these values
      const startYear = this.startYear - 2
      const endYear = this.endYear + 2

      const range = this.$d3.range(startYear, endYear + 1)

      return range.map(d => {
        const f = this.dataObject.filter(x => +x.year === d)
        let amount = 0
        if (f.length) {
          amount = Math.abs(f[0].amount)
        }
        return {
          year: d,
          value: amount
        }
      })
    },
    sliderWidth () {
      return this.width - 2 * this.sliderMarginLeft
    },
    xScale () {
      return this.$d3.scaleBand()
        .domain(this.domain)
        .range([0, this.chartWidth])
        .paddingInner([0.02])
        .paddingOuter([0.1])
    },
    yScale () {
      const max = this.$d3.max(this.computedData, d => d.value)

      return this.$d3.scaleLinear()
        .domain([0, max])
        .range([this.chartHeight, 0])
    },
    chartHeight () {
      return this.height - this.margin.top - this.margin.bottom - this.sliderHeight
    },
    chartWidth () {
      return this.width - this.margin.left - this.margin.right
    },
    bars () {
      return this.computedData.filter(x => this.domain.indexOf(x.year) > -1)
    },
    sliderMarginLeft () {
      return this.width * 0.15
    },
    maximumSliderRange () {
      return Math.round(this.computedData.length / 2.5)
    }
  }
}
</script>

<style lang="scss">
  @import 'OverviewIncomeExpensesChart.scss';
</style>
